'use client';
import React, { useEffect, useRef } from 'react';


import {
    Container,
    Typography,
    Card,
    CardActionArea,
    CardContent,
    Grid,
} from "@mui/material";
import Link from 'next/link';
import Image from "next/image";

export default function Home() {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const restartVideo = () => {
                video.currentTime = 0;
                video.play();
            };

            const delay = setTimeout(restartVideo, 30000);
            return () => clearTimeout(delay);
        }
    }, []);


  return (
      <>
          <video autoPlay muted width="100%" ref={videoRef}>
              <source src="/video/Animation2.webm" type="video/webm"/>
              <source src="/video/Animation2.mp4" type="video/mp4"/>
              <source src="/video/Animation2.ogv" type="video/ogg"/>
          </video>

          <Container maxWidth="xl" sx={{ py: 2 }}>
              <Card>
                  <CardContent>
                      <Typography variant="h5" sx={{ py: 1, fontSize: { xs: '1rem', sm: '1.5rem' }, color: 'rgba(0,128,128, 1)', textAlign: 'center' }}>Willkommen bei IMS, Industrie – Montage - Service - Ihr Partner im präzisen Maschinenbau in Lüneburg</Typography>

                      <Typography variant="body1" sx={{py: 2, xs: '4rem', sm: '3rem', textAlign: 'center', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                          Herzlich willkommen auf der offiziellen Website von IMS, Ihrem verlässlichen Partner im Maschinenbau mit Sitz im Herzen von Lüneburg. Seit 1989 bieten wir innovative und maßgeschneiderte Lösungen in den Bereichen Kabelbäume, Schaltschränke und Lagerlogistik.
                      </Typography>

                      <Typography gutterBottom variant="h5" sx={{ py: 1, fontSize: { xs: '1rem', sm: '1.5rem' }, color: 'rgba(0,128,128, 1)', textAlign: 'center' }}>
                          Unsere Expertise im Überblick
                      </Typography>
                      <Typography variant="body1" sx={{py: 2, textAlign: 'center', fontSize: { xs: '1rem', sm: '1.2rem' }}}>
                          Kabelbäume nach Maß: Unsere hochqualifizierten Mitarbeiter stehen für maßgeschneiderte Kabelbaum-Lösungen, die höchsten Qualitäts- und Leistungsstandards entsprechen. Von der Konzeption bis zur Fertigung - wir setzen auf Präzision und Zuverlässigkeit.
                      </Typography>

                      <Typography variant="body1" sx={{py: 1, textAlign: 'center', fontSize: { xs: '1rem', sm: '1.2rem' }}}>
                          Schaltschränke für höchste Ansprüche: Wir designen und produzieren Schaltschränke, die nicht nur höchste Sicherheitsstandards erfüllen, sondern auch für eine optimale Funktionalität und Bedienbarkeit in Ihren Anwendungen sorgen. Vertrauen Sie auf unsere langjährige Erfahrung und unser technisches Know-how.
                      </Typography>

                      <Typography variant="body1" sx={{py: 1, textAlign: 'center', fontSize: { xs: '1rem', sm: '1.2rem' }}}>
                          Effiziente Lagerlogistik: Unsere Lagerlogistiklösungen sind darauf ausgerichtet, Ihre Betriebsabläufe zu optimieren und Ihre Wirtschaftlichkeit zu steigern. Wir bieten nicht nur Lagerlösungen, sondern entwickeln ganzheitliche Logistikkonzepte, die auf Ihre individuellen Bedürfnisse zugeschnitten sind.
                      </Typography>
                  </CardContent>
              </Card>

              <Typography variant="h5" sx={{py: 2, color: 'rgba(0,128,128, 1)'}}>Unsere Leistungen</Typography>

              <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/gravurarbeiten">
                                  <Image
                                      src={"/static/images/gravur-kategorie.png"}
                                      alt="Gravurarbeiten"
                                      width={500}
                                      height={500}
                                  />
                              <CardContent>
                                  <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)'}}>
                                      Gravurarbeiten
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                      Präzise Qualitätsarbeit für Ihre Objektbeschriftungen
                                  </Typography>
                              </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/kabelbaumkonfektionierung">
                                  <Image
                                      src={"/static/images/kabel-kategorie.png"}
                                      alt="Kabelbaumkonfektionierung"
                                      width={500}
                                      height={500}
                                  />
                                  <CardContent>
                                      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)' }}>
                                          Kabelbaumkonfektionierung
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                          Von Einzelader bis zur komplexen Verdrahtung
                                      </Typography>
                                  </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/lagerlogistik">
                                  <Image
                                      src={"/static/images/logistik-kategorie.png"}
                                      alt="Tagerlogistik"
                                      width={500}
                                      height={500}
                                  />
                                  <CardContent>
                                      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)' }}>
                                          Lagerlogistik
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                          Kompletten Lagerkomissionierung, just in time oder Langzeitlagerung
                                      </Typography>
                                  </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/laser-blechbearbeitung">
                                  <Image
                                      src={"/static/images/laser-kategorie.png"}
                                      alt="Laser Blechbearbeitung"
                                      width={500}
                                      height={500}
                                  />
                                  <CardContent>
                                      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)' }}>
                                          Laser Blechbearbeitung
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                          Individuelle Fertigung Ihrer Bleche nach Maß
                                      </Typography>
                                  </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/schaltschrankbau">
                                  <Image
                                      src={"/static/images/schaltschrank-kategorie.png"}
                                      alt="Schaltschrankbau"
                                      width={500}
                                      height={500}
                                  />
                                  <CardContent>
                                      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)' }}>
                                          Schaltschrankbau
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                          Maßanfertigung bis hin zu komplexen Schaltschranksystemen
                                      </Typography>
                                  </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <Card>
                          <CardActionArea>
                              <Link href="/dienstleitungen/trockeneistrahlen">
                                  <Image
                                      src={"/static/images/eisstrahl-kategorie.png"}
                                      alt="Trockeneistrahlen"
                                      width={500}
                                      height={500}
                                  />
                                  <CardContent>
                                      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', color: 'rgba(0,128,128, 1)' }}>
                                          Trockeneistrahlen
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                          Modernste Technologie für sauberste Ergebnisse
                                      </Typography>
                                  </CardContent>
                              </Link>
                          </CardActionArea>
                      </Card>
                  </Grid>
              </Grid>


          </Container>
      </>
  )
}